import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { setMessage } from "./message";
import { toast } from 'react-toastify';

export const getCources = createAsyncThunk(
  "activity/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getPublicContent(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contactus = createAsyncThunk(
  "activity/contactus",
  async (body,thunkAPI) => {
    try {
      const response = await userService.contactus(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addProject = createAsyncThunk(
  "activity/add",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addProject(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editActivity = createAsyncThunk(
  "activity/edit",
  async (body,thunkAPI) => {
    try {
      const response = await userService.editActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const removeActivity = createAsyncThunk(
  "activity/delete",
  async (body,thunkAPI) => {
    try {
      const response = await userService.removeActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const singleActivity = createAsyncThunk(
  "activity/single",
  async (body,thunkAPI) => {
    try {
      const response = await userService.singleActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getProjectList = createAsyncThunk(
  "activity/user",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getProjectList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getProjectMediaList = createAsyncThunk(
  "activity/user",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getProjectMediaList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addMedia = createAsyncThunk(
  "activity/add",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addMedia(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const removeProject = createAsyncThunk(
  "project/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeProject(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const removeMidea = createAsyncThunk(
  "media/remove",
  async (body, thunkAPI) => {
    try {
      const response = await userService.removeMidea(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contactUs = createAsyncThunk(
  "contactus/add",
  async (body, thunkAPI) => {
    try {
      const response = await userService.contactUs(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contact_me_caseStudy = createAsyncThunk(
  "contactus/contact_me_caseStudy",
  async (body, thunkAPI) => {
    try {
      const response = await userService.contact_me_caseStudy(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const contact_us_hovsoga = createAsyncThunk(
  "contact_us_hovsoga/add",
  async (body,thunkAPI) => {
    try {
      const response = await userService.contact_us_hovsoga(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const contact_us_hovsoga_list = createAsyncThunk(
  "contact_us_hovsoga_list/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.casestudy_contact_list(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contact_us_list = createAsyncThunk(
  "contact_us_list/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.contact_us_list(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const casestudy_contact_list = createAsyncThunk(
  "contact_us_hovsoga_list/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.casestudy_contact_list(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = {}
const courseSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: {
    [getCources.fulfilled]: (state, action) => {
      state.allcourse = action.payload.data;
    },
    [getCources.rejected]: (state, action) => {
      state.allcourse = null;
    },
  },
});


const { reducer } = courseSlice;
export default reducer;
