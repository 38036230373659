import React, { useState } from 'react';
import {  Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ConfirmPopup = (prop) => {
    const [show, setShow] = useState(true);
    const { t } = useTranslation();
    const handleClose = () => {
        setShow(false);
        prop.handleDeleteFalse();
     }
     const handleOk=() => {
        setShow(false);
        prop.handleDeleteTrue();
     }
    return (
        <>
           <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton className="text-white d-block block text-center">
                    <Modal.Title className="d-block font-32"> {prop.message}</Modal.Title>
                </Modal.Header>
              <Modal.Footer>
                 <Button onClick={handleClose} variant="primary" type="button" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown' > {t('Cancle')} </Button>
                 <Button onClick={handleOk} variant="primary" className='font-18 min-200 mt-9 w-sm-100 wow fadeInDown'>{t('Ok')}</Button>
              </Modal.Footer>
           </Modal>
  
        </>
     )
}
export default ConfirmPopup;