import { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FilmPopup from "./FilmPopup";
import SocialMedia from "./SocialMedia";

const GridBlock = ({ title }) => {
    const [show, setShow] = useState(false);
    const [showSocial, setShowSocial] = useState(false);
    const [editId, setEditId] = useState();
    const [name, setName] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            // $('.grid').each(function(){
            $('.grid3').isotope({
                itemSelector: '.grid-item',
                // }
            });
            // })

        }, 1000)

    }, [])
    const OpenDiloag = (id, name) => {
        setEditId(id);
        setName(name);
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setShowSocial(false);
        //  setShowCancle(false)
    }
    const OpenSocial = (id) => {
        setEditId(id);
        setName(name);
        setShowSocial(true)

    }

    const OpenContact = () => {
        navigate('/kontakt');
    }
    return (
        <>

            <section className="pb-md-15 pb-14 section-block">
                <Container>
                    <div className="text-center pb-8">
                        <p className="font-22 fw-light mb-0">{title}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                            <path d="M0.5 1L9.08815 9L17.5 1" stroke="white" />
                        </svg>
                    </div>
                    <div className="grid grid3 overlay-wrap">
                        <div className="grid-item width-33" onClick={() => OpenDiloag(1, 'Företagsfilm')} >
                            <Image src="./assets/images/TV - 1.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Företagsfilm</span>
                        </div>
                        <div className="grid-item width-33" onClick={() => OpenDiloag(2, 'Reklamfilm')}>
                            <Image src="./assets/images/TV2.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Reklamfilm</span>
                        </div>
                        <div className="grid-item width-66" onClick={() => OpenDiloag(4, 'Narrativ film')}>
                            <Image src="./assets/images/TV4.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Narrativ film</span>
                        </div>
                        <div className="grid-item width-33" onClick={() => OpenDiloag(3, 'Drönarfilm')}>
                            <Image src="./assets/images/TV3.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Drönarfilm</span>
                        </div>

                        <div className="grid-item width-33 mobile-height-190 only-desk d-none" onClick={() => OpenSocial(5, 'Sociala medier')}>
                            <Image src="./assets/images/TV5.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Sociala medier</span>
                        </div>
                        <div className="grid-item width-33 mobile-height-190 only-mobile d-none" onClick={() => OpenSocial(5, 'Sociala medier')}>
                            <Image src="./assets/images/TV5Mobile.png" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Sociala medier</span>
                        </div>
                        
                        <div className="grid-item width-33" onClick={() => OpenDiloag(7, 'Podcast')}>
                            <Image src="./assets/images/TV6.webp" className="only-desk d-none"  />
                            <Image src="./assets/images/TV6Mobile.png" className="only-mobile d-none mobile-height-190"  />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Podcast</span>
                        </div>

                        <div className="grid-item width-33" onClick={() => OpenDiloag(6, 'Livestream')}>
                            <Image src="./assets/images/TV7.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Livestream</span>
                        </div>
                        <div className="grid-item width-66" onClick={() => OpenDiloag(8, 'Musikvideo')}>
                            <Image src="./assets/images/TV8.webp" />
                            <span className="position-absolute bottom-text text-white mobile-font-12">Musikvideo</span>
                        </div>
                    </div>
                </Container>
            </section>
            {show && editId !== null &&
                <FilmPopup show={show} editId={editId} name={name} handleClose={handleClose} />
            }
            {showSocial && editId != null &&
                <SocialMedia show={showSocial} editId={editId} name={name} handleClose={handleClose} />
            }

        </>
    )
}

export default GridBlock;