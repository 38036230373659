import { Accordion, Button, Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import FAQ from "../component/FAQ";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
// import Gallery from 'react-photo-gallery';
import { useEffect } from "react";

const Package = () => {
    useEffect(() => {
        $('.grid').isotope({
            itemSelector: '.grid-item',
            masonry: {
                columnWidth: 100
            }
        });

    }, [])
    return (
        <>
            <section className="banner-wrapper pb-md-15 pb-11 mt-neg40">
                <Container>
                    {/* <Link to="/" className="btn btn-link btn-back p-0 mb-9 d-md-none" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                        <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE"/>
                    </svg>
                </Link> */}
                    <div className="block text-center font-18 mb-md-84 mb-40">
                        <h1 className="font-56 font-secondary fw-semibold">Paket</h1>
                        <p className="font-18 fw-light letter-xs">Här är några av våra färdiga paket Självklart går det att sätta ihop unika paket efter önskemål.</p>
                    </div>

                    <Row className="gx-0 mb-md-20 gy-lg-40 gy-20">
                        <Col lg={4} className="order-md-0 order-1">
                            <div className="boxblock py-20 px-30">
                                <div className="font-18 text-center fw-light letter-xs block mb-20">
                                    <h1 className="font-secondary mb-2">Pro</h1>
                                    <p>För dig som tar levla upp era sociala medier.</p>
                                </div>
                                <div className="d-flex justify-content-between font-16 letter-xs fw-light border-bottom pricebox-info block gap-20 pb-3 align-items-end mb-3">
                                    <h4 className="letter-sm mb-0">22.500 SEK </h4>
                                    <span className="mb-0">Per månad</span>
                                </div>
                                <ul className="listblock d-flex flex-column ps-0 fw-light mb-0">
                                    <li>1 Inspelningsdag / mån</li>
                                    <li>8st - 30 sekundersklipp</li>
                                    <li>10 Professionella foton</li>
                                </ul>
                                <div className="text-center packetcontact-block position-absolute">
                                    <Link to="/kontakt" className="btn btn-primary">Kontakt</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="order-md-1 order-0">
                            <div className="boxblock py-20 px-30 active">
                                <div className="font-18 text-center fw-light letter-xs block mb-20">
                                    <h1 className="font-secondary mb-2">Starter</h1>
                                    <p>Perfekt för dig som vill doppa tårna i videoproduktion.</p>
                                </div>
                                <div className="d-flex justify-content-between font-16 letter-xs fw-light border-bottom pricebox-info block gap-20 pb-3 align-items-end mb-3">
                                    <h4 className="letter-sm mb-0">12.500 SEK  </h4>
                                    <span className="mb-0">Per månad</span>
                                </div>
                                <ul className="listblock d-flex flex-column ps-0 fw-light mb-0">
                                    <li>1/2 Inspelningsdag /mån</li>
                                    <li>4st - 30 sekunderklipp</li>
                                    <li>10 Professionella foton</li>
                                </ul>
                                <div className="text-center packetcontact-block position-absolute">
                                    <Link to="/kontakt" className="btn btn-primary">Kontakt</Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="order-2">
                            <div className="boxblock py-20 px-30">
                                <div className="font-18 text-center fw-light letter-xs block mb-20 border-bottom pricebox-info pb-3">
                                    <h1 className="font-secondary mb-2">One-timer</h1>
                                    <p>För engångsproduktioner gäller individuell prissättning.</p>
                                </div>
                                {/* <div className="d-flex justify-content-between font-16 letter-xs fw-light border-bottom pricebox-info block gap-20 pb-3 align-items-end mb-3">
                            <h4 className="letter-sm mb-0">22.500 SEK </h4>
                            <span className="mb-0">Per månad</span>
                        </div> */}
                                <ul className="listblock d-flex flex-column ps-0 fw-light mb-0">
                                    <li>Intervjuer / podcast</li>
                                    <li>Företagsfilm</li>
                                    <li>Drönare</li>
                                    <li>Reklamfilm</li>
                                    <li>Videoannonser</li>
                                </ul>
                                <div className="text-center packetcontact-block position-absolute">
                                    <Link to="/kontakt" className="btn btn-primary">Kontakt</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <FAQ />
        </>
    )
}

export default Package;