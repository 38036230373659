
const HavsogaFooter = () => {
    return (
        <>
<div className="brand-names d-flex flex-wrap align-items-center">
        <div className="text-center">
          <h5 className="font-24 fw-light mb-0">JANNE</h5>
          <h2 className="font-44 fw-light mb-0">HANSSON</h2>
        </div>
        <div className="text-center">
          <h5 className="font-24 fw-light mb-0">ELIN</h5>
          <h2 className="font-44 fw-light mb-0">BRYNZÉR</h2>
        </div>
        <div className="text-center">
          <h5 className="font-24 fw-light mb-0">ANDREAS</h5>
          <h2 className="font-44 fw-light mb-0">BERGGREN</h2>
        </div>
        <div className="text-center">
          <h5 className="font-24 fw-light mb-0">LUDVIG</h5>
          <h2 className="font-44 fw-light mb-0">KARMALM</h2>
        </div>
     </div>
     <div className="havsoga-homepage-content text-center">
      <p className="text-white font-20 fw-light max-744 mx-auto text-uppercase">En film AV <span className="fw-normal font-24"> Marcus Martinson</span> <br className="d-sm-none d-block" /> baserat på dikter av <span className="fw-normal font-24"> Siv Andersson </span> FOTOGRAFERAT av <span className="fw-normal font-24"> Lukas Martinson </span> musik av <span className="fw-normal font-24"> Benjamin ALinder </span> </p>
      <p className="text-white font-20 fw-light max-1030 mx-auto">Filmen utforskar livets cykliska natur, förlust och avslut parallellt med hopp och nystart. Den speglar även ett samhälle i förändring, där lokalbefolkningen sakta försvinner och tillsammans med dem den lokala kulturen, livsstil och dialekter.</p>
      <p className="text-white font-20 fw-light text-uppercase">Endast på filmlabbet.com</p>
     </div>
</>
    )
}

export default HavsogaFooter;