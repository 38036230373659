import { useState, useEffect } from "react";
import { Button, Image, Form } from "react-bootstrap";
import HavsogaFooter from "./HavsogaFooter";
import { useNavigate } from "react-router-dom";
import SeeTrailer from "./SeeTrailer";
const HavsogaRegistreradPage = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
const gotoHome = () =>{
  navigate('/havsoga');
}
const handleClose = () => {
  setShow(false);
  
}
const OpenDiloag = () => {
setShow(true);
}
  return (
    <>
    <section className="havsoga-banner-wrap page-1">
    {isDesktop ? (
          <>
            <div className="bg-image" style={{backgroundImage: `url('/assets/images/havsoga-banner-6.png')`}} />
            <div className="bg-image-mobile" style={{visibility: 'hidden', backgroundImage: `url('/assets/images/havsoga-banner-6-mobile.png')`}} />
          </>
        ) : (
          <div className="bg-image" style={{backgroundImage: `url('/assets/images/havsoga-banner-6-mobile.png')`}} />
        )}
      <div className="havsoga-header d-flex align-items-center justify-content-between ">
        <div className="logo cursor_pointer" onClick={gotoHome}><Image src="../assets/images/Havsöga-logo.png"></Image></div>
        <Button variant="white" className="rounded-pill cursor_pointer" onClick={OpenDiloag}>Se trailer</Button>
      </div>
      <div className="slide-content">

        <div className="banner-middle-content">
          <h6 className="font-36 fw-semibold text-center">Du är nu registrerad!</h6>
          <p className="font-26 mb-0 fw-normal text-center">Vi har skickat ett mail till dig med mer infomation.</p>
        
        </div>
      </div>
    </section>
    {show  &&
                <SeeTrailer show={show} src={'https://player.vimeo.com/video/930189196?h=3e2898be56'}  handleClose={handleClose} />
            }
   <HavsogaFooter/>
    </>
  );
};

export default HavsogaRegistreradPage;
