import React, { useState, useEffect } from 'react';
import { Container, Image, Table, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import moment from 'moment'
import { getProjectMediaList,removeMidea } from '../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import AddMedia from './AddMedia';
import { useParams } from 'react-router-dom';
import ConfirmPopup from '../component/ConfirmPopup';
const ProjectList = () => {
    const dispatch = useDispatch();
    const { categoty_id,id } = useParams();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [allCources, setAllCources] = useState();
    const [editId, setEditId] = useState(null);
    const { role_id } = useSelector((state) => state.auth.user || {});
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null
      });
    const openShow = (id) => {
        setEditId(id)
        setShow(true);
    }
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
      let data = {
        'categoty_id':categoty_id,
        'id': id,
      }
        dispatch(getProjectMediaList(data)).then((course) => {
            setAllCources(course.payload?.data);
        });
    }
    const callbackModal = () => {
        setShow(false);
        refresh();
    }


    const handleDelete = (id) => {
        setPopup({
          show: true,
          id,
        });
      };
      const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
    
          let data = {
            'is_delete': 1,
            'id': popup.id
          }
          dispatch(removeMidea(data))
            .unwrap()
            .then((res) => {
              if (res.status === 200) {
                refresh();
              }
            })
            .catch((e) => {
              console.log(e)
            });
    
          setPopup({
            show: false,
            id: null,
          });
        }
      };
      const handleDeleteFalse = () => {
        setPopup({
          show: false,
          id: null,
        });
      };
    
    return (
        <>
            <div>
               
                <Container>
                    {/* Starts Table */}
                    <div className='wow fadeInDown'>
                        <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
                            <span className="d-block  font-24 fw-light mb-2">Frukostföreläsningar</span>
                           
                            <div className="right-button">
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openShow(null)}>Lägg till</Button>
                                {show && editId === null &&
                                    <AddMedia show={show} callbackModal={callbackModal} category_id={categoty_id} project_id={id} ></AddMedia>
                                }
                            </div>
                          
                        </div>
                        <Table bordered hover responsive className='text-center'>
                            <thead>
                                <tr>
                                    <th>Nummer</th>
                                    <th>Rubrik</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allCources && allCources.length > 0 && allCources?.map((item, index) => (
                                        <tr key={item.id} >
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td >{item.title}</td>
                                            
                                            <td>
                                            <span className='table-img'>
                                              <Image src={item.image} alt='Outlook' width={68} height={68} className="img-fit" />
                                          </span>
                                            </td>
                                            <td>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                
                                                <a onClick={() => handleDelete(item.id)} className=' btn-link icon-ic-trash text-white  action-btn'>
                                                <img className='pass_img' src={`/assets/images/delete.png`} ></img>
                                                </a>
                                                {popup.show && (
                                                        <ConfirmPopup
                                                        handleDeleteTrue={handleDeleteTrue}
                                                        handleDeleteFalse={handleDeleteFalse}
                                                        message={('You sure you wanna delete')}
                                                        />
                                                        )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                        {allCources && allCources.length === 0 &&
                            <p className='text-center'>Tomt</p>
                        }
                    </div>
                    {/* Ends Table */}
                </Container>
                
            </div>
        </>
    )
}

export default ProjectList;