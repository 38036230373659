import { Col, Container, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import Footer from "../component/Footer";
import GridBlock from "../component/GridBlock";

const ThankYou = () => {
    return (
        <div className="pt-md-31">
            <div className="mb-90">
                <Container>
                    <div className="maxw-890 mx-auto">
                        <Row className="align-items-center gx-30 gy-40">
                            <Col md={6}>
                                <div className="font-18 fw-light d-flex flex-column gap-3 block text-md-left text-center">
                                    <h1 className="font-56 font-secondary mb-0 maxw-387 mobile-mx-auto">Tack för ditt meddelande!</h1>
                                    <span className="font-24 fw-light maxw-330 mobile-mx-auto">Vi kommer att kontakta dig inom 24 timmar.</span>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="text-md-left text-center">
                                    <Image src="/assets/images/thanks.gif" alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <section className="text-center pb-md-15 pb-11">
                <Container>
                    <div className="d-flex justify-content-between flex-wrap gap-8">
                    <div className="logo-wrap">
                            <Image src="/assets/images/logos/factoringgruppen.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/Docens_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/ytterbygg_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky_logo.png" alt="Spotify" />
                        </div>
                    </div>
                </Container>
            </section>
            {/* <GridBlock title="Spana in vår portfolio" /> */}
            {/* <FAQ /> */}
        </div>
    )
}

export default ThankYou;