import { Button, Container, Image, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { singleActivity } from '../store/api/course.api';
import SeeTrailer from '../component/SeeTrailer'
const Individual = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [single, setSingleProject] = useState();
    const { id, categoty_id } = useParams();
    const [show, setShow] = useState(false);
    const OpenContact = () => {
        navigate('/kontakt');
    }
    useEffect(() => {
        refresh();
    }, []);
    const refresh = () => {
        let data = {
            'categoty_id': categoty_id,
            'id': id,
        }
        dispatch(singleActivity(data)).then((single) => {
            setSingleProject(single.payload?.data);
        });
    }
    const handleClose = () => {
        setShow(false);
        
    }
    const OpenDiloag = () => {
      setShow(true);
    }
    return (
        <>
        <div className="individual-bg-section"><Image src={single?.image}></Image></div>
        <div className="individual-page-banner">
            <section className="pb-lg-40">
                <Container>
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div className="maxw-918 mx-auto">
                            <h1 className="font-56 text-white fw-semibold mb-2 letter-space-1">{single?.title}</h1>
                            <span className="letter-sm fw-light font-24  mx-auto d-block  " style={{ 'max-width': '713px' }}>{single?.sub_title}</span>
                            <Button variant="primary" className="mt-3" onClick={() => OpenDiloag()}>Se film</Button>
                        </div>
                    </div>
                    <div className="individual-page-banner-bottom-text text-white font-20 fw-light max-744 mx-auto text-center" dangerouslySetInnerHTML={{ __html: single?.about_producer }}></div>

                </Container>
            </section>
        </div>
            <section  className={`individual-grid-wrap pt-md-80 pb-80 pt-80 pb-md-150 }`}>
            
                <Container>
                    <p className={`text-white text-center font-20 mb-0  fw-normal max-1030 mx-auto font-clash-grotesk ${single && single.media.length > 0? '' : ''}`} dangerouslySetInnerHTML={{ __html: single?.about_film }}></p>
                    {single && single.media.length > 0 &&
                    <div className="individual-grids max-1030 mx-auto">
                        {
                            single && single.media.length > 0 && single.media.map((mediai, index) => (
                                <div class="ind-grid-item item1">
                                    <Image className="rounded-3" src={mediai?.media_image}></Image>
                                </div>
                            ))
                        }
                    </div>
                 }
                </Container>
                
            </section>
            <section className="text-center pb-md-150 pb-80  z-1 position-relative">
                <Container>
                    <h2 className="font-56 font-secondary mb-md-6 mb-3">Vill du veta mer?</h2>
                    <Link to="/kontakt" className="btn btn-primary" onClick={OpenContact}>Kontakt</Link>
                </Container>
            </section>
            {show  &&
                <SeeTrailer show={show} src={single?.video_url}  handleClose={handleClose} />
            }
        </>
    )
}

export default Individual;