import { useState, useEffect } from "react";
import { Button, Image, Form } from "react-bootstrap";
import HavsogaFooter from "./HavsogaFooter";
import SeeTrailer from "./SeeTrailer";
import Slider from "react-slick";

const HavsogaPage2 = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleClose = () => {
    setShow(false);
    
}
const OpenDiloag = () => {
  setShow(true);
}
const settings2 = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  fade: true,
  cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
}
const slides = [
  {
    id: 1,
    desktopImageUrl: '/assets/images/havsoga-banner-1.png',
    mobileImageUrl: '/assets/images/havsoga-banner-1-mobile.png',
  },
  {
    id: 2,
    desktopImageUrl: '/assets/images/havsoga-banner-2.png',
    mobileImageUrl: '/assets/images/havsoga-banner-2-mobile.png',
  },
  {
    id: 3,
    desktopImageUrl: '/assets/images/havsoga-banner-3.png',
    mobileImageUrl: '/assets/images/havsoga-banner-3-mobile.png',
  },
  {
    id: 4,
    desktopImageUrl: '/assets/images/havsoga-banner-4.png',
    mobileImageUrl: '/assets/images/havsoga-banner-4-mobile.png',
  },
  {
    id: 5,
    desktopImageUrl: '/assets/images/havsoga-banner-5.png',
    mobileImageUrl: '/assets/images/havsoga-banner-5-mobile.png',
  },
];
  return (
    <>
     <section className="havsoga-banner-wrap page-2">
        <Slider {...settings2} className='bg-image' >
          {
            slides && slides.length > 0 && slides?.map((item, index) => (
              <>
              <img className="d-md-block d-none" src={item.desktopImageUrl}></img>
              <img className="d-md-none d-block" src={item.mobileImageUrl}></img>
              </>
            ))
          }
        </Slider>
        <div className="havsoga-header d-flex align-items-center justify-content-between">
          <div className="logo"><Image src="../assets/images/Havsöga-logo.png"></Image></div>
        </div>
        <div className="slide-content">
        
        
          <div className="banner-middle-content">
          <h6 className="font-44 fw-semibold text-center">Havsöga</h6>
          <p className="font-24 mb-0 fw-normal text-center mb-3">Tack för ditt köp!</p>
          <div className="havsoga-banner-btns gap-3 d-flex align-items-center justify-content-center">
            <Button variant="white" className="rounded-pill" onClick={() => OpenDiloag()}>Se film</Button>
          </div>
        </div>
         
         
        </div>

      </section>
    {show  &&
                <SeeTrailer show={show} src={'https://player.vimeo.com/video/928372714?h=cb5d168179'}  handleClose={handleClose} />
            }
   <HavsogaFooter />
    </>
  );
};

export default HavsogaPage2;
