import axios from "./httpService";
  const API_URL = axios.Api;

const addProject = (body) => {
  return axios.post(API_URL + "category/add",body);
};
const getPublicContent = (body) => {
  return axios.post(API_URL + "category/list",body);
};
const contactus = (body) => {
  return axios.post(API_URL + "activity/contactus",body);
};
//activity/list
const removeActivity = (body) => {
  return axios.post(API_URL + "activity/delete",body);
};
const editActivity = (body) => {
  return axios.post(API_URL + "category/edit",body);
};

const singleActivity = (body) => {
  return axios.post(API_URL + "category/single",body);
};
const getProjectList = (body) => {
  return axios.post(API_URL + "category/project_list",body);
};
const getProjectMediaList = (body) => {
  return axios.post(API_URL + "project/media",body);
};
const addMedia = (body) => {
  return axios.post(API_URL + "project/add",body);
};
const removeProject = (body) => {
  return axios.post(API_URL + "project/remove",body);
};
const removeMidea = (body) => {
  return axios.post(API_URL + "media/remove",body);
};
const contactUs = (body) => {
  return axios.post(API_URL + "contactus/add", body);
};
const contact_me_caseStudy = (body) => {
  return axios.post(API_URL + "contactus/contact_me_caseStudy", body);
};

const contact_us_hovsoga = (body) => {
  return axios.post(API_URL + "contact_us_hovsoga/add",body);
};
const contact_us_hovsoga_list = (body) => {
  return axios.post(API_URL + "category/havsoga_contact_list",body);
};
const casestudy_contact_list = (body) => {
  return axios.post(API_URL + "category/casestudy_contact_list",body);
};
const contact_us_list = (body) => {
  return axios.post(API_URL + "category/contact_list",body);
};

const userService = {
  getPublicContent,
  contactus,
  addProject,
  removeActivity,
  editActivity,
  singleActivity,
  getProjectList,
  getProjectMediaList,
  addMedia,
  removeProject,
  removeMidea,
  contactUs,
  contact_us_hovsoga,
  contact_us_hovsoga_list,
  casestudy_contact_list,
  contact_me_caseStudy,
  contact_us_list
};

export default userService