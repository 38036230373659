import { Button, Image, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import Footer from "./Footer";
import { getProjectList } from '../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const FilmPopup = (props) => {
    const dispatch = useDispatch();
    const [allProject, setAllProject] = useState();
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        speed: 500,
        adaptiveHeight: true,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        afterChange: () => {
            setTimeout(() => {
                $('.grid2').isotope({
                    itemSelector: '.grid-item',
                    // }
                });
            }, 1000)
        }
    };
    useEffect(() => {
        setTimeout(() => {
            // $('.grid').each(function(){
            $('.grid2').isotope({
                itemSelector: '.grid-item',
                // }
            });
            // })

        }, 1000)

    }, [props.show])
    const close = () => {
        props.handleClose();
    }
    useEffect(() => {
        refresh();
    }, []);
    const refresh = () => {
        let data = {
            'id': props.editId,
        }
        dispatch(getProjectList(data)).then((course) => {
            setAllProject(course.payload?.data);
        });
    }
    return (
        <Modal show={props.show} onHide={close} size="xl" className="modal-blockwrap">
            <Link to="/" className="btn btn-link btn-back p-0 mb-9 d-md-none" onClick={close}>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                    <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE" />
                </svg>
            </Link>
            <Modal.Header closeButton >
                <h2 className="font-56 mb-md-9 mb-6 font-secondary text-center">{props.name}</h2>
            </Modal.Header>
            <Modal.Body>
                <div className="maxw-776 mx-auto">
                    <div className="sliderwrapper-block">
                        <Slider {...settings} className="filmslider" >
                            {
                                allProject && allProject.length > 0 && allProject?.map((item, index) => (
                                    <>
                                        <div className="shadow-wrapper shadow-iframewrapper">
                                            {/* <Image src="./assets/images/Excel.png" alt="Dronefilm"  /> */}
                                            <iframe className="w-100 rounded-3 shadow-wrapper" width={682} height={376} src={item?.video_url} title="YouTube video player" frameborder="0" allowfullscreen></iframe>

                                        </div>

                                    </>
                                ))
                            }
                        </Slider>
                        <div className="pt-20 position-relative">
                            <span className="font-20 popup-title">{allProject && allProject.length > 0 ? allProject[activeSlide]?.title : ''}</span>
                            <span className="popup-subtitle">{allProject && allProject.length > 0 ? allProject[activeSlide]?.sub_title : ''}</span>
                        </div>
                        {allProject && allProject.length > 0 && allProject[activeSlide]?.description &&
                            <div>

                                <div className="text-center pb-md-40 pb-6 pt-lg-15 pt-110 ">
                                    <p className="font-18 fw-light mb-0">Läs mer om projektet</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                                        <path d="M0.5 1L9.08815 9L17.5 1" stroke="white" />
                                    </svg>
                                </div>
                                <div className="letter-xs font-18 fw-light block mb-13">

                                    <div dangerouslySetInnerHTML={{ __html: allProject && allProject.length > 0 ? allProject[activeSlide]?.description : '' }}></div>

                                </div>
                            </div>
                        }
                        {allProject && allProject.length > 0 && allProject[activeSlide]?.media.length > 0 &&
                            <div className="mobile-pb-80 pb-80 pt-80">
                                <h4 className="font-24 mb-3 letter-sm fw-normal">Bakom kulisserna</h4>
                                <div className="grid grid2 overlay-wrap griditems-block">
                                    {
                                        allProject && allProject.length > 0 && allProject[activeSlide]?.media.length > 0 && allProject[activeSlide].media?.map((mediai, index) => (
                                            <div className="grid-item width-33 nooverlay">
                                                <Image src={mediai?.media_image} />
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        }
                        <div className={`text-center  d-md-block  ${allProject && allProject.length > 0 && allProject[activeSlide]?.description ? '' : 'pt-110'}`}  >
                            <h2 className="font-56  mb-6 font-secondary text-center">Vill du veta mer?</h2>
                            <Link to="/kontakt" className="btn btn-primary">Kontakt</Link>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default FilmPopup; 