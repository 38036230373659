import React, { useState, useEffect } from 'react';
import { Container, Image, Table, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import moment from 'moment'
import { getCources,removeActivity } from '../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
const CategoryList = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [allCources, setAllCources] = useState();
    const [editId, setEditId] = useState(null);
    const { role_id } = useSelector((state) => state.auth.user || {});
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null
      });
    const openShow = (id) => {
        setEditId(id)
        setShow(true);
    }
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
        dispatch(getCources({})).then((course) => {
            setAllCources(course.payload?.data);
        });
    }
    const callbackModal = () => {
        setShow(false);
        refresh();
    }


    const handleDelete = (id) => {
        setPopup({
          show: true,
          id,
        });
      };
      const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
    
          let data = {
            'is_delete': 1,
            'slug': popup.id
          }
          dispatch(removeActivity(data))
            .unwrap()
            .then((res) => {
              if (res.status === 200) {
                refresh();
              }
            })
            .catch((e) => {
              console.log(e)
            });
    
          setPopup({
            show: false,
            id: null,
          });
        }
      };
      const handleDeleteFalse = () => {
        setPopup({
          show: false,
          id: null,
        });
      };
      const openUserDetai = (id) => {
        navigate('/admin/projects/'+id);
      }

      const openPage = (page) => {
        navigate(page);
      }
    return (
        <>
            <div>
               
                <Container>
                    {/* Starts Table */}
                    <div className='wow fadeInDown'>
                        <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
                            <span className="d-block  font-24 fw-light mb-2">Category list</span>
                           
                            <div className="right-button">
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openPage('/admin/havsoga_list')}>Havsoga</Button>
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openPage('/admin/contact-list')}>Contact</Button>
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openPage('/admin/casestudy-list')}>Casestudy</Button>

                            </div>
                          
                        </div>
                        <Table bordered hover responsive className='text-center'>
                            <thead>
                                <tr>
                                    <th>Nummer</th>
                                    <th>Rubrik</th>
                                    <th>Slug</th>
                                    <th>Order</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allCources && allCources.length > 0 && allCources?.map((item, index) => (
                                        <tr key={item.id} >
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td onClick={()=>openUserDetai(item.id)}>{item.category_name}</td>
                                            <td >{item.slug}</td>
                                            <td >{item.order}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                        {allCources && allCources.length === 0 &&
                            <p className='text-center'>Tomt</p>
                        }
                    </div>
                    {/* Ends Table */}
                </Container>
                
            </div>
        </>
    )
}

export default CategoryList;