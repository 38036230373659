import { useState, useEffect } from "react";
import { Button, Image, Form } from "react-bootstrap";
import HavsogaFooter from "./HavsogaFooter";
import Slider from "react-slick";
import { contact_us_hovsoga } from '../store/api/course.api';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import SeeTrailer from "./SeeTrailer";

const HavsogaHomepage1 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [bgImage, setBgImage] = useState('');
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [deadline, setDeadline] = useState('2024-06-05T02:12:00.000Z');
  const [value, setValue] = useState({ email: "", name: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showFilm, setFilmShow] = useState(false);
  const [displayTimer, setDisplayTimer] = useState(true);
  const getTime = (date) => {
    const time = Date.parse(date) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
    if (time <= 0) {
      setDisplayTimer(false);
  } else {
      setDisplayTimer(true)
  }   

  };
  useEffect(() => {
    if (deadline) {
      const interval = setInterval(() => getTime(deadline), 1000);
      return () => clearInterval(interval);
    }
  }, [deadline]);
  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    fade: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  }
  const slides = [
    {
      id: 1,
      desktopImageUrl: '/assets/images/havsoga-banner-1.png',
      mobileImageUrl: '/assets/images/havsoga-banner-1-mobile.png',
    },
    {
      id: 2,
      desktopImageUrl: '/assets/images/havsoga-banner-2.png',
      mobileImageUrl: '/assets/images/havsoga-banner-2-mobile.png',
    },
    {
      id: 3,
      desktopImageUrl: '/assets/images/havsoga-banner-3.png',
      mobileImageUrl: '/assets/images/havsoga-banner-3-mobile.png',
    },
    {
      id: 4,
      desktopImageUrl: '/assets/images/havsoga-banner-4.png',
      mobileImageUrl: '/assets/images/havsoga-banner-4-mobile.png',
    },
    {
      id: 5,
      desktopImageUrl: '/assets/images/havsoga-banner-5.png',
      mobileImageUrl: '/assets/images/havsoga-banner-5-mobile.png',
    },
  ];
  // const isMobile = window.matchMedia("(max-width: 768px)").matches;
const onChange = (e) => {
    setValue({
        ...value,
        [e.target.name]: e.target.value,
    });
};
  const onAdd = () => {
    dispatch(contact_us_hovsoga(value))
    .unwrap()
    .then((res) => {
      if (res.status === 200) {
            navigate('/havsoga-registrerad');
      }
    })
    .catch(() => {
       
    });
  
  };
  const handleClose = () => {
    setShow(false);
    setFilmShow(false);
    
}
const OpenDiloag = () => {
  setShow(true);
}
const openMovie = () => {
  // window.open('/havsoga-player', '_blank')
  setFilmShow(true);
}
  return (
    <>
      <section className="havsoga-banner-wrap page-2">
        <Slider {...settings2} className='bg-image' >
          {
            slides && slides.length > 0 && slides?.map((item, index) => (
              <>
              <img className="d-md-block d-none" src={item.desktopImageUrl}></img>
              <img className="d-md-none d-block" src={item.mobileImageUrl}></img>
              </>
            ))
          }
        </Slider>
        {/* <div className="havsoga-header d-flex align-items-center justify-content-between">
          <div className="logo"><Image src="../assets/images/Havsöga-logo.png"></Image></div>
          <Button variant="white" className="rounded-pill" onClick={() => OpenDiloag()}>Se trailer</Button>
        </div> */}
        <div className="slide-content">
       
         
          <div className="banner-middle-content">
          <h6 className="font-44 fw-semibold text-center">Havsöga</h6>
          <p className="font-24 mb-0 fw-normal text-center mb-3">Vid en resas slut, börjar en annan</p>
          <div className="havsoga-banner-btns gap-3 d-flex align-items-center justify-content-center">
            <Button variant="primary" className="rounded-pill" onClick={()=>openMovie()}>Se film</Button>
            <Button variant="white" className="rounded-pill" onClick={() => OpenDiloag()}>Se trailer</Button>
          </div>
        </div>
         
         
        </div>

      </section>
      {show  &&
                <SeeTrailer show={show} src={'https://player.vimeo.com/video/930189196?h=3e2898be56'}  handleClose={handleClose} />
            }

        {showFilm  &&
                <SeeTrailer show={showFilm} src={'https://player.vimeo.com/video/928372714?h=cb5d168179'}  handleClose={handleClose} />
            }
      <HavsogaFooter />
    </>
  );
};

export default HavsogaHomepage1;
