import React, { useState, useEffect } from 'react';
import { contact_me_caseStudy } from '../store/api/course.api'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Button, Col, Container, Image, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../component/Footer";


const FormPage = () => {
    const [value, setValue] = useState({ email: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    const onAdd = () => {
        let data = {
            email: value.email,
            phone: value?.phone,
            name: value?.name,
            business_name: value?.business_name
        }
        dispatch(contact_me_caseStudy(data))
            .unwrap()
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    navigate('/player-page');
                }

            })
            .catch(() => {

            });
    };
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        customPaging: function (i) {
            return (
                <div className='d-inline-flex align-items-center'>

                    <a current={i} goTo={i => slider.current.slickGoTo(i)}>
                        {i + 1}
                    </a>
                    <span className="total-slides">/ 3</span>
                </div>
            );
        },
        // customPaging: (i) => {
        //     return <CustomDots slides={slides} current={i} goTo={i => slider.slickGoTo(i)} />;
        // },
    };
    const slider = React.useRef(null);
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className="bg-gradientblock bottom-gradient pt-md-30 pt-6  landingpage homepage overflow-hidden">
            <nav className="bg-transparent text-white navbar navbar-expand-lg navbar-light mb-9">
                <Container className="justify-content-center">
                    <Link to="/" className="navbar-brand me-0">
                        <Image src="/assets/images/logo.svg" alt="Filmlabbet" />
                    </Link>
                </Container>
            </nav>
            <section className="pt-md-65 pt-0  pb-md-15 pb-11 mt-neg40" id="contackForm">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="font-18 fw-light d-flex flex-column gap-3 mb-md-0 mb-9 block max-476 text-md-left text-center mx-md-0 mx-auto">
                                <h4 className='fw-light mb-0'> Video - 17 minuter</h4>
                                <h1 className="font-46 fw-semibold font-secondary mb-0">
                                Hur du lyckas på  <br/> <span className='text-primary text-decoration-underline'>sociala medier</span> med  ditt <span className='text-primary text-decoration-underline'>byggbolag</span>
                                </h1>
                                <div className="opacity-90">
                                    <p className="letter-xs fw-light mb-20">Sammanfattat i 5 enkla steg som du kan göra själv redan idag!
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='form-block-wrapper position-relative'>
                                <div className='w-100 filter-img-wrap'>
                                    <Image src="./assets/images/form-page-img.png" alt="Image1" className="rounded-4 img-fit" />
                                </div>
                                <div className="form-section blockwrap p-6">
                                    <Form className="d-flex flex-column align-items-center gap-20">
                                        <Form.Group className='w-100' controlId="inputName">
                                            <Form.Control type="text" placeholder="Namn" name='name' value={value.name} onChange={onChange} />
                                        </Form.Group>

                                        <Form.Group className='w-100' controlId="inputPost">
                                            <Form.Control type="email" placeholder="E-post" name='email' value={value.email} onChange={onChange} />
                                        </Form.Group>

                                        <Form.Group className='w-100' controlId="inputTel">
                                            <Form.Control type="tel" placeholder="Telefon" name='phone' value={value.phone} onChange={onChange} />
                                        </Form.Group>

                                        <Form.Group className='w-100'>
                                            <Form.Control type="text" placeholder="Företag" name='business_name' value={value.business_name} onChange={onChange} />
                                        </Form.Group>
                                        <Button variant="primary" type="button"  onClick={onAdd} >
                                            Se video
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pt-md-65 pt-0 pb-md-150 pb-100 mt-neg40">
                <Container>
                    <h2 className="font-56 font-secondary mb-2 mobile-mx-auto text-center pt-md-120">Sociala medier för <span className='text-primary text-decoration-underline'>byggbolag</span></h2>
                    <p className="font-18 fw-light mb-0 text-center">I videon har Marcus sammanfattat hur dom moderna byggbolagen anväder sociala medier</p>
                    
                    <Row className='flex-md-row flex-column-reverse mt-9'>
                        <Col md={6} className='text-md-start text-center'>
                            <div className='form-block-wrapper position-relative'>
                                <div className='w-100' style={{"height" : "275px"}}>
                                    <Image src="./assets/images/landing-block-img.png" alt="Image1" className="rounded-4 img-fit" />
                                </div>
                            </div>
                            <Button className='d-md-none d-block mt-3 mx-auto' variant="primary" type="button"  onClick={() => scrollToSection('contackForm')} >
                                Se video
                            </Button>
                        </Col>
                        <Col md={6}>
                            <div className="font-18 fw-light d-flex flex-column align-items-md-start align-items-center justify-content-between h-100 gap-3 mb-md-0 mb-5 block">
                                <ul className='mb-2 list-style-none ps-0 text-md-start text-center'>
                                    <li className='font-24 fw-light mb-1'>✅ Hur dom strukturerar upp profilen</li>
                                    <li className='font-24 fw-light mb-1'>✅ Vad dom lägger upp för inlägg & stories</li>
                                    <li className='font-24 fw-light mb-1'>✅ Hur dom använder betald marknadsföring för att nå ut till nya kunder</li>
                                </ul>
                                <Button className='d-md-block d-none' variant="primary" type="button"  onClick={() => scrollToSection('contackForm')} >
                                    Se video
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className='pb-md-150 pb-100 result-new-section d-md-block d-none'>
                <Container>
                    <div className='text-center mb-8'>
                        <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                        <p className="font-18 fw-light mb-4">Dom första 3 månaderna med <span className='fw-semibold'>@Docens.se </span> </p>
                    </div>
                    <div class="result-grid-container">
                        <div class="grid-item-box double-height d-flex flex-column justify-content-between">
                            <p className='font-24 fw-light'>Uttalande ifrånkunden</p>
                            <div>
                                <span className='mb-2 d-block'>
                                    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.184 0.92H11.208V6.128H8.464C7.288 6.128 6.728 6.464 6.728 7.864V8.816H11.488V18.616H0.68V7.92C0.68 3.216 3.424 0.92 8.184 0.92ZM21.848 0.92H24.816V6.128H22.072C20.896 6.128 20.392 6.464 20.392 7.864V8.816H25.152V18.616H14.288V7.92C14.288 3.216 17.088 0.92 21.848 0.92Z" fill="white" />
                                    </svg>
                                </span>
                                <p className='font-18 fw-light'>Filmabbet har pricksäkert och prisvärt hjälpt oss att få en engagerad publik med flera tusentals följare över flera olika kanaler
                                    på bara några månader.</p>
                            </div>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <p className='font-24 fw-light'>Över</p>
                            <h2 className='font-56 fw-bold'> 1  miljon</h2>
                            <p className='font-24 fw-light mb-0'>visningar totalt</p>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> + 5 340</h2>
                            <p className='font-24 fw-light mb-0'>Följare på Instagram</p>
                        </div>
                        <div class="grid-item-box double-height">
                            <Image className='rounded-3 img-fit' src="/assets/images/result-img-person-2.png" alt='result-img-person-2'></Image>
                        </div>
                        <div class="grid-item-box double-height">
                            <Image className='rounded-3 img-fit' src="/assets/images/result-img-person-1.png" alt='result-img-person-2'></Image>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> + 8 511</h2>
                            <p className='font-24 fw-light mb-0'>Följare på Tiktok</p>

                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> 110</h2>
                            <p className='font-24 fw-light mb-0'>dagar</p>
                        </div>
                        <div class="grid-item-box double-width">
                            <p className='font-24 fw-light mb-3'>Kommentarer på instagram</p>
                            <p className='font-14'>@mandy_stockholm<br />
                                Vad i ....🤯 TACK! 2024 kommer bli bättre känner jag nu 😂</p>
                            <p className='font-14 mb-0'>@yezlor<br />
                                🤦🏽‍♀️😅😅 Alltså TACK!! Allt kommer bli så mycket enklare nu 🤩😅👌🏼</p>
                        </div>
                    </div>
                </Container>
            </section> */}

            {/* <section className='pb-md-150 pb-100 result-mobile d-md-none d-block'>
                <Container>
                    <div className='text-center mb-8'>
                        <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                        <p className="font-18 fw-light mb-4">Dom första 3 månaderna med <span className='fw-semibold'>@Docens.se </span> </p>
                    </div>
                    <div className='result-mobile-box text-white mb-3'>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Dagar</p>
                            <p className='font-32 fw-bold mb-0'>Uttalande ifrånkunden</p>
                        </div>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Visningar</p>
                            <p className='font-32 fw-bold mb-0'>+ 1  000 000</p>
                        </div>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Nya följare på Instagram</p>
                            <p className='font-32 fw-bold mb-0'>+ 5 340</p>
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-5.png" alt="Filmlabbet" />
                            <p className='font-18 fw-light mb-0'>Nya följare på Tiktok</p>
                            <p className='font-32 fw-bold mb-0'>+ 8 511</p>
                        </div>
                    </div>
                    <div className='result-mobile-box text-center'>
                        <Image className='rounded-3' src="/assets/images/result-img-person-1.png" alt='result-img-person-2'></Image>
                    </div>
                </Container>
            </section> */}

            {/* <section className='pb-md-150 pb-100'>
                <Container className='text-center'>
                    <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                    <p className="font-18 fw-light mb-0">Såhär kan det gå på under 100 dagar 😎</p>
                    <div className='d-flex flex-md-row flex-column gap-2 justify-content-between align-items-center mt-9'>
                        <div>
                            <Image src="/assets/images/Result-img-1.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Arrow_1.png" alt="Filmlabbet" />
                            <Image className='d-md-none d-block' src="/assets/images/Arrow_2.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image src="/assets/images/Result-img-2.png" alt="Filmlabbet" />
                        </div>
                    </div>
                    <div className='d-flex gap-2 justify-content-between align-items-center mt-md-12 mt-9'>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-3.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image src="/assets/images/Result-img-4.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-5.png" alt="Filmlabbet" />
                        </div>
                    </div>
                </Container>
            </section> */}

            {/* <section className="text-center pb-md-150 pb-100">
                <Container>
                    <h2 className="font-48 font-secondary mb-md-6 mb-3 maxw-1000 mx-auto spacing-neg">
                    Hur vi fyllde @Docens instagram med 5 340
                        <span className='text-primary'> engagerade följare </span> på 110 dagar </h2>
                    <Button variant="primary" type="button" onClick={() => scrollToSection('contackForm')} >
                        Se video
                    </Button>
                </Container>
            </section> */}

            {/* <section className="pb-md-150 pb-100">
                <Container>
                    <div class="text-center pb-20">
                        <p class="font-22 fw-light mb-0">Såhär säger kunden</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none"><path d="M0.5 1L9.08815 9L17.5 1" stroke="white"></path></svg>
                    </div>
                    <div className="testimonial-block p-20  mx-auto">
                        <Slider {...settings} ref={slider}>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Filmabbet har pricksäkert och prisvärt hjälpt oss att få en engagerad publik med flera tusentals följare över flera olika kanaler”
                                        på bara några månader.</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/Booky__Logo white.png" alt="Booky" />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Thomas Andersson</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </section> */}

            {/* <Footer noLine={true} /> */}
        </div>
    )
}

export default FormPage;