import { Button, Image, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import Footer from "./Footer";
import { getProjectList } from '../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

const HavsogaTrailer = (props) => {
    
    useEffect(() => {
       

    }, [props.show])
    const close = () => {
        props.handleClose();
    }
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%', // Set the width of the modal
        height: 'auto', // Set the height of the modal
        display: 'flex',
        flexDirection: 'column'
      };
    
      const iframeStyle = {
        width: '100%', // Make iframe full width of the modal content
        height: '450px', // Make iframe full height of the modal content
        border: 'none'
      };
    
    return (
        <Modal show={props.show} onHide={close} size="lg" className="havsoga-trailer-modal" centered >
            <Modal.Header closeButton >
            </Modal.Header>
            <Modal.Body  style={modalStyle}>
                <iframe  style={iframeStyle}  className="w-100 shadow-wrapper" src={props.src} title="Havsoga" allow="autoplay; fullscreen;" frameborder="0" allowfullscreen ></iframe>
            </Modal.Body>
        </Modal>
    )
}

export default HavsogaTrailer; 