import { Button, Container, Image, Row, Col } from "react-bootstrap";
import Header from "../component/Header";
import { useEffect, useState } from "react";
import FAQ from "../component/FAQ";
import FilmPopup from "../component/FilmPopup";
import SocialMedia from "../component/SocialMedia";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import GridBlock from "../component/GridBlock";

const HomeO = () => {

    const navigate = useNavigate();

    const OpenContact = () => {
        navigate('/kontakt');
    }
    const openCal = () =>{
        window.open('https://cal.com/filmlabbet','_blank')
    }
    const openSocial =() =>{
        navigate('/socialamedier');
    }
    return (
        <div className="pt-md-31 pt-70">
            <section className="banner-wrapper pb-md-150 pb-100">
                <Container>
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div className="maxw-918 mx-auto mb-5">
                            <h1 className="font-56 text-white font-secondary mb-2 letter-space-1">Produktionsbolaget med extra stort fokus på sociala medier 👋</h1>
                            <span className="letter-sm fw-light font-24 maxw-776 mx-auto d-block">Vi hjälper tjänsteföretag i Västsverige med strategi, innehåll och annonsering på sociala medier.</span>
                        </div>
                        <div>
                            <Button onClick={openCal} variant="primary" >Boka möte</Button>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="pb-md-150 pb-100">
                <Container>  
                    <Row className="gx-30 gy-40">
                        <Col md={6}>
                            <div className="font-18 fw-light d-flex flex-column gap-md-3 gap-2 block text-md-start text-center">
                                <p className="text-grey mb-0">Sociala medier</p>
                                <h2 className="font-42 font-secondary mb-0 mobile-mx-auto">Redo för en publik fylld med <span className='text-primary'> varma leads?</span></h2>
                                <p className="text-grey mb-0">Varje månad hjälper vi företag i Västsverige att växa en engagerade publik på sociala medier fylld med varma leads.</p>
                            </div>
                            <div className="mt-4 mobile-btn-blocks p-0 text-md-start text-center">
                                <Button variant="primary" className=" me-5" onClick={openCal}>Boka möte</Button>
                                <Button variant="white"  onClick={openSocial}>Läs mer</Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='video-homeblock'>
                                <Image src="/assets/images/home-gif-new.gif" className="w-100 img-fit radius-14" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <GridBlock title="Vi gör alla typer av content" />

            <FAQ />

            <section className="text-center pb-md-15 pb-11 d-md-block d-none">
                <Container>
                    <h2 className="font-56 font-secondary mb-md-6 mb-3 spacing-neg">Vill du veta mer?</h2>
                    <Link to="/kontakt" className="btn btn-primary">Kontakt</Link>
                </Container>
            </section>
            <section className="text-center pb-md-15 pb-11">
                <Container>
                    <div className="d-flex justify-content-between flex-wrap gap-8">
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/factoringgruppen.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/Docens_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/ytterbygg_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky_logo.png" alt="Spotify" />
                        </div>
                    </div>
                </Container>
            </section>



        </div>
    )
}

export default HomeO;