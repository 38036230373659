import { Button, Container, Image, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate();

    const OpenContact = () => {
        navigate('/kontakt');
    }
    const OpenPortfolio = () => {
        navigate('/portfolio');
    }
   
    return (
        <section className="banner-wrapper pt-100">
        <Container>
            <div className="text-center d-flex flex-column justify-content-center">
                <div className="maxw-918 mx-auto mb-30">
                    <h1 className="font-56 text-white font-secondary mb-3 letter-space-1">Produktionsbolaget för de lagom stora företagen</h1>
                    <span className="letter-sm fw-light font-18 maxw-776 mx-auto d-block">Vi hjälper er att sticka ut i en skrollande värld!</span>
                </div>
                <div className="gap-sm-20 gap-2 d-flex align-items-center justify-content-center">
                    <Button onClick={OpenContact} variant="primary" >Kontakt</Button>
                    <Button onClick={OpenPortfolio} variant="white">Portfolio</Button>
                </div>
            </div>
        </Container>
    </section>
    )
}

export default Home;