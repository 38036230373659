// import { useRef } from 'react';
import React, { useRef, useEffect } from 'react';
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Footer from "../component/Footer";
import FAQ from "../component/FAQ";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GridBlock from "../component/GridBlock";

const LandingPage = ({ slides }) => {
    const navigate = useNavigate();
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        customPaging: function (i) {
            return (
                <div className='d-inline-flex align-items-center'>

                    <a current={i} goTo={i => slider.current.slickGoTo(i)}>
                        {i + 1}
                    </a>
                    <span className="total-slides">/ 3</span>
                </div>
            );
        },
        // customPaging: (i) => {
        //     return <CustomDots slides={slides} current={i} goTo={i => slider.slickGoTo(i)} />;
        // },
    };

    const slider = React.useRef(null);
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        fade: true,
        initialSlide: 1
    }
    const gotoContact = () => {
        navigate('/kontakt');
    }
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);
    return (
        <div className="mt-neg40 bg-gradientblock overflow-hidden">
            {/* <nav className="bg-transparent text-white navbar navbar-expand-lg navbar-light">
                <Container className="justify-content-center">
                    <Link to="/" className="navbar-brand me-0">
                        <Image src="/assets/images/logo.svg" alt="Filmlabbet" />
                    </Link>
                </Container>
            </nav> */}
            <section className="pt-md-65 pt-45 mb-md-40 pb-100">
                <Container>
                    <Row className="g-0 align-items-center">
                        <Col lg={7}>
                            <div className="text-lg-left text-center">
                            <p className="fw-light font-24 pe-lg-3">Sociala medier</p>
                            <h2 className="font-53 mobile-font-46 font-secondary fw-semibold mb-md-20 mb-8px">Redo för en publik <br className='d-md-block d-none' /> fylld av<span className='text-primary'> varma leads?</span> </h2>
                                <p className="fw-light mb-40 font-24 pe-lg-3 maxw-575">Varje månad hjälper vi företag i Västsverige att växa en engagerade publik på sociala medier fylld av varma leads.</p>
                                <Button className="d-lg-block d-none" variant='primary' onClick={gotoContact}>Kontakt</Button>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <Button className="btn btn-primary d-lg-none d-block mx-auto mb-40 " onClick={gotoContact}>Kontakt</Button>
                            <div className="text-center ">
                                <Slider {...settings2} className='slider-boxwrap'>
                                    <div>
                                        <div className="top-iframe mx-auto position-relative text-start">
                                            {/* <iframe src="https://player.vimeo.com/video/879787029?h=25827aa75a" width={243} height={442}></iframe> */}
                                            <iframe width={243} height={442} src="https://player.vimeo.com/video/885668572?h=e30d3a851d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;background=1&amp;app_id=58479" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                                            <div className="social-box first-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                                    <path d="M13.1175 0.70105C5.3404 0.70105 0.729492 9.92286 0.729492 9.92286C0.729492 9.92286 5.3404 19.1447 13.1175 19.1447C20.7101 19.1447 25.321 9.92286 25.321 9.92286C25.321 9.92286 20.7101 0.70105 13.1175 0.70105ZM13.0252 3.77499C16.4373 3.77499 19.1731 6.54153 19.1731 9.92286C19.1731 13.3349 16.4373 16.0707 13.0252 16.0707C9.64391 16.0707 6.87737 13.3349 6.87737 9.92286C6.87737 6.54153 9.64391 3.77499 13.0252 3.77499ZM13.0252 6.84892C11.3346 6.84892 9.95131 8.2322 9.95131 9.92286C9.95131 11.6135 11.3346 12.9968 13.0252 12.9968C14.7159 12.9968 16.0992 11.6135 16.0992 9.92286C16.0992 9.61547 15.9762 9.33881 15.9147 9.06216C15.6688 9.55399 15.177 9.92286 14.5622 9.92286C13.7015 9.92286 13.0252 9.2466 13.0252 8.38589C13.0252 7.77111 13.3941 7.27928 13.8859 7.03336C13.6093 6.94114 13.3326 6.84892 13.0252 6.84892Z" fill="white" />
                                                </svg></span><span className="count-text">162 000</span>
                                            </div>
                                            <div className="social-box second-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
                                                    <path d="M0.212891 0.0315552V17.3414L4.54035 13.0139L8.86782 17.3414V0.0315552H0.212891Z" fill="white" />
                                                </svg></span><span className="count-text">809</span>
                                            </div>
                                            <div className="social-box third-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                    <path d="M11.3021 0.404372C10.8215 0.454963 10.3662 0.783801 10.1132 1.26441C9.78439 1.92209 7.35604 6.80408 6.87543 7.28469C6.39482 7.7653 5.76244 7.99296 5.05417 7.99296V18.1111H13.9075C14.4387 18.1111 14.894 17.7822 15.0964 17.3269C15.0964 17.3269 17.7018 9.96599 17.7018 9.25772C17.7018 8.54945 17.1453 7.99296 16.437 7.99296H12.6428C11.9345 7.99296 11.378 7.36057 11.378 6.72819C11.378 6.09581 12.3645 2.73154 12.5669 2.07386C12.7692 1.41618 12.4404 0.707916 11.7827 0.480258C11.6056 0.429668 11.4792 0.379077 11.3021 0.404372ZM-0.00488281 7.99296V18.1111H2.52464V7.99296H-0.00488281Z" fill="white" />
                                                </svg></span><span className="count-text">14 235</span>
                                            </div>
                                            <div className="social-box fourth-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                                                    <path d="M0.160156 0.19873V13.3872L2.79785 10.7495H5.43554V2.83642H13.3486V0.19873H0.160156ZM8.07323 5.47411V16.0249H18.624L21.2617 18.6626V5.47411H8.07323Z" fill="white" />
                                                </svg></span><span className="count-text">40</span>
                                            </div>
                                            <div className="social-box fifth-box">
                                                <span className="social-img social-image">
                                                    <Image src='/assets/images/Maskgroup1.png' />
                                                </span>
                                                <div className='count-text text-start comment-desc'>
                                                    <span className='comment-name mb-4px d-inline-block'>Beatrice Christersso</span>
                                                    <p className='mb-0'>Är det kameran som är såhär bra eller har
                                                        du efterredigerat mycket? 🥰</p>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0' width="17" height="15" viewBox="0 0 17 15" fill="none">
                                                    <g opacity="0.6">
                                                        <path d="M8.10583 4.48919H8.73153C8.73153 3.55408 9.11299 2.73469 9.72068 2.12555C10.3549 1.50983 11.178 1.13034 12.0904 1.13034C13.0255 1.13034 13.8449 1.51181 14.454 2.11951C15.0697 2.75372 15.4492 3.57682 15.4492 4.48919C15.4492 5.42586 15.0665 6.24642 14.4571 6.85587L8.10583 13.2071L1.75773 6.859C1.14195 6.22477 0.762417 5.40163 0.762417 4.48919C0.762417 3.55408 1.14388 2.73468 1.75158 2.12554C2.38579 1.50983 3.20889 1.13034 4.12127 1.13034C5.05639 1.13034 5.87578 1.5118 6.48492 2.1195C7.10064 2.75371 7.48013 3.57681 7.48013 4.48919H8.10583Z" stroke="white" stroke-width="1.2514" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="top-iframe mx-auto position-relative text-start">
                                            {/* <iframe src="https://player.vimeo.com/video/879787029?h=25827aa75a" width={243} height={442}></iframe> */}
                                            <iframe width={243} height={442} src="https://player.vimeo.com/video/885668642?h=3e0e72478f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;background=1&amp;app_id=58479" frameborder="0" allowfullscreen="allowfullscreen"></iframe>

                                            <div className="social-box first-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                                    <path d="M13.1175 0.70105C5.3404 0.70105 0.729492 9.92286 0.729492 9.92286C0.729492 9.92286 5.3404 19.1447 13.1175 19.1447C20.7101 19.1447 25.321 9.92286 25.321 9.92286C25.321 9.92286 20.7101 0.70105 13.1175 0.70105ZM13.0252 3.77499C16.4373 3.77499 19.1731 6.54153 19.1731 9.92286C19.1731 13.3349 16.4373 16.0707 13.0252 16.0707C9.64391 16.0707 6.87737 13.3349 6.87737 9.92286C6.87737 6.54153 9.64391 3.77499 13.0252 3.77499ZM13.0252 6.84892C11.3346 6.84892 9.95131 8.2322 9.95131 9.92286C9.95131 11.6135 11.3346 12.9968 13.0252 12.9968C14.7159 12.9968 16.0992 11.6135 16.0992 9.92286C16.0992 9.61547 15.9762 9.33881 15.9147 9.06216C15.6688 9.55399 15.177 9.92286 14.5622 9.92286C13.7015 9.92286 13.0252 9.2466 13.0252 8.38589C13.0252 7.77111 13.3941 7.27928 13.8859 7.03336C13.6093 6.94114 13.3326 6.84892 13.0252 6.84892Z" fill="white" />
                                                </svg></span><span className="count-text">345 562</span>
                                            </div>
                                            <div className="social-box second-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
                                                    <path d="M0.212891 0.0315552V17.3414L4.54035 13.0139L8.86782 17.3414V0.0315552H0.212891Z" fill="white" />
                                                </svg></span><span className="count-text">3 419</span>
                                            </div>
                                            <div className="social-box third-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                    <path d="M11.3021 0.404372C10.8215 0.454963 10.3662 0.783801 10.1132 1.26441C9.78439 1.92209 7.35604 6.80408 6.87543 7.28469C6.39482 7.7653 5.76244 7.99296 5.05417 7.99296V18.1111H13.9075C14.4387 18.1111 14.894 17.7822 15.0964 17.3269C15.0964 17.3269 17.7018 9.96599 17.7018 9.25772C17.7018 8.54945 17.1453 7.99296 16.437 7.99296H12.6428C11.9345 7.99296 11.378 7.36057 11.378 6.72819C11.378 6.09581 12.3645 2.73154 12.5669 2.07386C12.7692 1.41618 12.4404 0.707916 11.7827 0.480258C11.6056 0.429668 11.4792 0.379077 11.3021 0.404372ZM-0.00488281 7.99296V18.1111H2.52464V7.99296H-0.00488281Z" fill="white" />
                                                </svg></span><span className="count-text"> 10 834</span>
                                            </div>
                                            <div className="social-box fourth-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                                                    <path d="M0.160156 0.19873V13.3872L2.79785 10.7495H5.43554V2.83642H13.3486V0.19873H0.160156ZM8.07323 5.47411V16.0249H18.624L21.2617 18.6626V5.47411H8.07323Z" fill="white" />
                                                </svg></span><span className="count-text">40</span>
                                            </div>
                                            <div className="social-box fifth-box">
                                                <span className="social-img social-image">
                                                    <Image src='/assets/images/Maskgroup3.png' />
                                                </span>
                                                <div className='count-text text-start comment-desc'>
                                                    <span className='comment-name mb-4px d-inline-block'>annasnyazoo</span>
                                                    <p className='mb-0'><span>@carroforsberg</span> Haha jag är helt hooked på
                                                        det här kontot! Följ 😍😂</p>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0' width="17" height="15" viewBox="0 0 17 15" fill="none">
                                                    <g opacity="0.6">
                                                        <path d="M8.10583 4.48919H8.73153C8.73153 3.55408 9.11299 2.73469 9.72068 2.12555C10.3549 1.50983 11.178 1.13034 12.0904 1.13034C13.0255 1.13034 13.8449 1.51181 14.454 2.11951C15.0697 2.75372 15.4492 3.57682 15.4492 4.48919C15.4492 5.42586 15.0665 6.24642 14.4571 6.85587L8.10583 13.2071L1.75773 6.859C1.14195 6.22477 0.762417 5.40163 0.762417 4.48919C0.762417 3.55408 1.14388 2.73468 1.75158 2.12554C2.38579 1.50983 3.20889 1.13034 4.12127 1.13034C5.05639 1.13034 5.87578 1.5118 6.48492 2.1195C7.10064 2.75371 7.48013 3.57681 7.48013 4.48919H8.10583Z" stroke="white" stroke-width="1.2514" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="top-iframe mx-auto position-relative text-start">
                                            {/* <iframe src="https://player.vimeo.com/video/879787029?h=25827aa75a" width={243} height={442}></iframe> */}
                                            <iframe width={243} height={442} src="https://player.vimeo.com/video/885668404?h=bd2eb154be&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;background=1&amp;app_id=58479" frameborder="0" allowfullscreen="allowfullscreen"></iframe>

                                            <div className="social-box first-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                                    <path d="M13.1175 0.70105C5.3404 0.70105 0.729492 9.92286 0.729492 9.92286C0.729492 9.92286 5.3404 19.1447 13.1175 19.1447C20.7101 19.1447 25.321 9.92286 25.321 9.92286C25.321 9.92286 20.7101 0.70105 13.1175 0.70105ZM13.0252 3.77499C16.4373 3.77499 19.1731 6.54153 19.1731 9.92286C19.1731 13.3349 16.4373 16.0707 13.0252 16.0707C9.64391 16.0707 6.87737 13.3349 6.87737 9.92286C6.87737 6.54153 9.64391 3.77499 13.0252 3.77499ZM13.0252 6.84892C11.3346 6.84892 9.95131 8.2322 9.95131 9.92286C9.95131 11.6135 11.3346 12.9968 13.0252 12.9968C14.7159 12.9968 16.0992 11.6135 16.0992 9.92286C16.0992 9.61547 15.9762 9.33881 15.9147 9.06216C15.6688 9.55399 15.177 9.92286 14.5622 9.92286C13.7015 9.92286 13.0252 9.2466 13.0252 8.38589C13.0252 7.77111 13.3941 7.27928 13.8859 7.03336C13.6093 6.94114 13.3326 6.84892 13.0252 6.84892Z" fill="white" />
                                                </svg></span><span className="count-text">4152</span>
                                            </div>
                                            <div className="social-box second-sec">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
                                                    <path d="M0.212891 0.0315552V17.3414L4.54035 13.0139L8.86782 17.3414V0.0315552H0.212891Z" fill="white" />
                                                </svg></span><span className="count-text">22</span>
                                            </div>
                                            <div className="social-box third-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                                    <path d="M11.3021 0.404372C10.8215 0.454963 10.3662 0.783801 10.1132 1.26441C9.78439 1.92209 7.35604 6.80408 6.87543 7.28469C6.39482 7.7653 5.76244 7.99296 5.05417 7.99296V18.1111H13.9075C14.4387 18.1111 14.894 17.7822 15.0964 17.3269C15.0964 17.3269 17.7018 9.96599 17.7018 9.25772C17.7018 8.54945 17.1453 7.99296 16.437 7.99296H12.6428C11.9345 7.99296 11.378 7.36057 11.378 6.72819C11.378 6.09581 12.3645 2.73154 12.5669 2.07386C12.7692 1.41618 12.4404 0.707916 11.7827 0.480258C11.6056 0.429668 11.4792 0.379077 11.3021 0.404372ZM-0.00488281 7.99296V18.1111H2.52464V7.99296H-0.00488281Z" fill="white" />
                                                </svg></span><span className="count-text">445</span>
                                            </div>
                                            <div className="social-box fourth-box">
                                                <span className="social-img"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                                                    <path d="M0.160156 0.19873V13.3872L2.79785 10.7495H5.43554V2.83642H13.3486V0.19873H0.160156ZM8.07323 5.47411V16.0249H18.624L21.2617 18.6626V5.47411H8.07323Z" fill="white" />
                                                </svg></span><span className="count-text">9</span>
                                            </div>
                                            <div className="social-box fifth-box">
                                                <span className="social-img social-image">
                                                    <Image src='/assets/images/Maskgroup2.png' />
                                                </span>
                                                <div className='count-text text-start comment-desc'>
                                                    <span className='comment-name mb-4px d-inline-block'>Iste</span>
                                                    <p className='mb-0'>wow 🥹🥹 urgullig</p>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0' width="17" height="15" viewBox="0 0 17 15" fill="none">
                                                    <g opacity="0.6">
                                                        <path d="M8.10583 4.48919H8.73153C8.73153 3.55408 9.11299 2.73469 9.72068 2.12555C10.3549 1.50983 11.178 1.13034 12.0904 1.13034C13.0255 1.13034 13.8449 1.51181 14.454 2.11951C15.0697 2.75372 15.4492 3.57682 15.4492 4.48919C15.4492 5.42586 15.0665 6.24642 14.4571 6.85587L8.10583 13.2071L1.75773 6.859C1.14195 6.22477 0.762417 5.40163 0.762417 4.48919C0.762417 3.55408 1.14388 2.73468 1.75158 2.12554C2.38579 1.50983 3.20889 1.13034 4.12127 1.13034C5.05639 1.13034 5.87578 1.5118 6.48492 2.1195C7.10064 2.75371 7.48013 3.57681 7.48013 4.48919H8.10583Z" stroke="white" stroke-width="1.2514" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pb-md-150 pb-100 mt-sm-0 mt-5">
                <Container>  
                    <Row className="gx-30 gy-40">
                        <Col md={6}>
                            <div className="font-18 fw-light d-flex flex-column gap-md-3 gap-2 block text-md-start text-center pe-md-6">
                                <p className="text-grey mb-0">Om oss</p>
                                <h2 className="font-42 font-secondary mb-0 mobile-mx-auto">Ett team av frilansande experter till er tjänst</h2>
                                <p className="text-grey mb-0">Till en tredjedel av kostnaden för en anställd får ni ett helt team av frilansande experter inom ämnerna sociala medier, media produktion och betald annonsering som varje månad hjälper er att planera, skapa, publicera och annonsera på sociala medier.</p>
                            </div>
                            <div className="mt-4 d-md-block d-none">
                                 <Button className="btn btn-primary  " onClick={gotoContact}>Kontakt</Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='video-homeblock'>
                                <Image src="/assets/images/home-gif-new.gif" className="w-100 img-fit radius-14" />
                            </div>
                            <div className="mt-4  d-md-none d-block text-center">
                                <Button className="btn btn-primary " onClick={gotoContact}>Kontakt</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='pb-md-150 pb-100 result-new-section d-md-block d-none'>
                <Container>
                    <div className='text-center mb-8'>
                        <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                        <p className="font-18 fw-light mb-4">Dom första 3 månaderna med <span className='fw-semibold'>@Docens.se </span> </p>
                    </div>
                    <div class="result-grid-container">
                        <div class="grid-item-box double-height d-flex flex-column justify-content-between">
                            <p className='font-24 fw-light'>Uttalande ifrånkunden</p>
                            <div>
                                <span className='mb-2 d-block'>
                                    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.184 0.92H11.208V6.128H8.464C7.288 6.128 6.728 6.464 6.728 7.864V8.816H11.488V18.616H0.68V7.92C0.68 3.216 3.424 0.92 8.184 0.92ZM21.848 0.92H24.816V6.128H22.072C20.896 6.128 20.392 6.464 20.392 7.864V8.816H25.152V18.616H14.288V7.92C14.288 3.216 17.088 0.92 21.848 0.92Z" fill="white" />
                                    </svg>
                                </span>
                                <p className='font-18 fw-light'>Filmabbet har pricksäkert och prisvärt hjälpt oss att få en engagerad publik med flera tusentals följare över flera olika kanaler
                                    på bara några månader.</p>
                            </div>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <p className='font-24 fw-light'>Över</p>
                            <h2 className='font-56 fw-bold'> 1  miljon</h2>
                            <p className='font-24 fw-light mb-0'>visningar totalt</p>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> + 5 340</h2>
                            <p className='font-24 fw-light mb-0'>Följare på Instagram</p>
                        </div>
                        <div class="grid-item-box double-height">
                            <Image className='rounded-3 img-fit' src="/assets/images/result-img-person-2.png" alt='result-img-person-2'></Image>
                        </div>
                        <div class="grid-item-box double-height">
                            <Image className='rounded-3 img-fit' src="/assets/images/result-img-person-1.png" alt='result-img-person-2'></Image>
                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> + 8 511</h2>
                            <p className='font-24 fw-light mb-0'>Följare på Tiktok</p>

                        </div>
                        <div class="grid-item-box text-center d-flex flex-column justify-content-center">
                            <h2 className='font-56 fw-bold'> 110</h2>
                            <p className='font-24 fw-light mb-0'>dagar</p>
                        </div>
                        <div class="grid-item-box double-width">
                            <p className='font-24 fw-light mb-3'>Kommentarer på instagram</p>
                            <p className='font-14'>@mandy_stockholm<br />
                                Vad i ....🤯 TACK! 2024 kommer bli bättre känner jag nu 😂</p>
                            <p className='font-14 mb-0'>@yezlor<br />
                                🤦🏽‍♀️😅😅 Alltså TACK!! Allt kommer bli så mycket enklare nu 🤩😅👌🏼</p>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='pb-md-150 pb-100 result-mobile d-md-none d-block'>
                <Container>
                    <div className='text-center mb-8'>
                        <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                        <p className="font-18 fw-light mb-4">Dom första 3 månaderna med <span className='fw-semibold'>@Docens.se </span> </p>
                    </div>
                    <div className='result-mobile-box text-white mb-3'>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Dagar</p>
                            <p className='font-32 fw-bold mb-0'>Uttalande ifrånkunden</p>
                        </div>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Visningar</p>
                            <p className='font-32 fw-bold mb-0'>+ 1  000 000</p>
                        </div>
                        <div className='mb-3'>
                            <p className='font-18 fw-light mb-0'>Nya följare på Instagram</p>
                            <p className='font-32 fw-bold mb-0'>+ 5 340</p>
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-5.png" alt="Filmlabbet" />
                            <p className='font-18 fw-light mb-0'>Nya följare på Tiktok</p>
                            <p className='font-32 fw-bold mb-0'>+ 8 511</p>
                        </div>
                    </div>
                    <div className='result-mobile-box text-center'>
                        <Image className='rounded-3' src="/assets/images/result-img-person-1.png" alt='result-img-person-2'></Image>
                    </div>
                </Container>
            </section>

            <section className="pb-md-150 pb-100 consulatant-wrapper text-center">
                <Container className="position-relative z-1">
                    <div className="font-18 fw-light  block text-left mb-40">
                        <h2 className="font-50 font-secondary mb-md-2 mb-1 mobile-mx-auto">Det här är ingen raketforskning...  🚀</h2>
                        <p>Bara strategiska inlägg marknadsförda mot rätt målgrupp.</p>
                    </div>
                    <Row>
                        <Col md={4} className='mb-5'>
                            <span className='mb-4 d-block'>
                                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M50.3994 14.4001L37.6965 27.1031C36.7592 28.0404 35.2396 28.0403 34.3024 27.1031L30.4965 23.2972C29.5592 22.3599 28.0396 22.3599 27.1024 23.2972L16.7994 33.6001" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.2002 7.19995V42.72C7.2002 45.4082 7.2002 46.7523 7.72336 47.7791C8.18356 48.6823 8.91786 49.4166 9.82104 49.8768C10.8478 50.4 12.1919 50.4 14.8802 50.4H50.4002" stroke="white" stroke-width="2.4" stroke-linecap="round"/>
                                </svg>
                            </span>
                            <h4 className='fw-semibold mb-2'>1. Innehållsstrategi</h4>
                            <p className='font-18 fw-light mb-0'>Vi analyserar målgruppen, varumärket, konkurrenter och skapar en innehållsstrategi med inlägg som eran målgruppen vill se.</p>
                        </Col>
                        <Col md={4} className='mb-5'>
                            <span className='mb-4 d-block'>
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M39.5147 9H27C21.3431 9 18.5147 9 16.7574 10.7574C15 12.5147 15 15.3431 15 21V51C15 56.6569 15 59.4853 16.7574 61.2426C18.5147 63 21.3431 63 27 63H45C50.6569 63 53.4853 63 55.2426 61.2426C57 59.4853 57 56.6569 57 51V26.4853C57 25.259 57 24.6459 56.7716 24.0946C56.5433 23.5433 56.1097 23.1097 55.2426 22.2426L43.7574 10.7574C42.8903 9.89027 42.4567 9.45672 41.9054 9.22836C41.3541 9 40.741 9 39.5147 9Z" stroke="white" stroke-width="3"/>
                                <path d="M41.566 42.2426L32.0181 38.1507C29.6426 37.1326 27 38.8751 27 41.4596V48.5405C27 51.125 29.6426 52.8675 32.0181 51.8494L41.566 47.7575C43.9903 46.7185 43.9903 43.2816 41.566 42.2426Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M39 9V21C39 23.8284 39 25.2426 39.8787 26.1213C40.7574 27 42.1716 27 45 27H57" stroke="white" stroke-width="3"/>
                                </svg>

                            </span>
                            <h4 className='fw-semibold mb-2'>2. Innehållsproduktion</h4>
                            <p className='font-18 fw-light mb-0'>Vi skapar innehållet som målgruppen vill se.</p>
                        </Col>
                        <Col md={4} className='mb-5'>
                            <span className='mb-4 d-block'>
                                <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="36" cy="36" r="24" stroke="white" stroke-width="3"/>
                                <path d="M54.6225 20.3545C52.4242 22.3826 49.6633 24.0273 46.5318 25.1743C43.4003 26.3213 39.9733 26.943 36.489 26.9963C33.0047 27.0495 29.5468 26.533 26.3556 25.4827C23.1644 24.4324 20.3164 22.8734 18.0097 20.9142" stroke="white" stroke-width="3"/>
                                <path d="M54.9666 51.9705C52.7477 49.8241 49.91 48.0822 46.666 46.8752C43.422 45.6682 39.8559 45.0274 36.2349 45.0009C32.6138 44.9743 29.0317 45.5626 25.7568 46.7218C22.482 47.8809 19.5993 49.5808 17.3248 51.6942" stroke="white" stroke-width="3"/>
                                <path d="M36 12V60" stroke="white" stroke-width="3"/>
                                <path d="M60 36H12" stroke="white" stroke-width="3"/>
                                </svg>
                            </span>
                            <h4 className='fw-semibold mb-2'>3. Annonsering</h4>
                            <p className='font-18 fw-light mb-0'>Vi publicerar innehållet med passande bildtext, #hashtags, trendande ljud och betalar för en plats i flödet hos målgruppen.</p>
                        </Col>
                    </Row>
                    <Button className="btn btn-primary mt-5 " onClick={gotoContact}>Kontakt</Button>

                </Container>
            </section>

            {/* <section className='pb-md-150 pb-100 '>
                <Container className='text-center'>
                    <h2 className="font-56 font-secondary mb-0 mobile-mx-auto">Resultat</h2>
                    <p className="font-18 fw-light mb-0">Såhär kan det gå på under 100 dagar 😎</p>
                    <div className='d-flex flex-md-row flex-column gap-2 justify-content-between align-items-center mt-9'>
                        <div>
                            <Image src="/assets/images/Result-img-1.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Arrow_1.png" alt="Filmlabbet" />
                            <Image className='d-md-none d-block' src="/assets/images/Arrow_2.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image src="/assets/images/Result-img-2.png" alt="Filmlabbet" />
                        </div>
                    </div>
                    <div className='d-flex gap-2 justify-content-between align-items-center mt-md-12 mt-9'>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-3.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image src="/assets/images/Result-img-4.png" alt="Filmlabbet" />
                        </div>
                        <div>
                            <Image className='d-md-block d-none' src="/assets/images/Result-img-5.png" alt="Filmlabbet" />
                        </div>
                    </div>
                </Container>
            </section> */}

            {/* <section className="pb-md-150 pb-100 consulatant-wrapper">
                <Container className="position-relative z-1">
                    <Row className="gx-lg-105 gx-40 gy-40">
                        <Col md={6}>
                            <div className="font-18 fw-light d-flex flex-column gap-3 block text-left">
                                <p className="text-grey mb-0">I blickfånget</p>
                                <h2 className="font-50 font-secondary mb-0 mobile-mx-auto">Brand Accelerator Månadspaket</h2>
                                <ul className="home-block-listing">
                                    <li><span className="fw-medium">1.Mågruppsanalys</span>  - Vi analyserar målgruppen, varumärke och konkurrenter</li>
                                    <li><span className="fw-medium">2.Innehållsstrategi</span>  - Vi skapar en innehållstrategi med content som målgruppen vill se</li>
                                    <li><span className="fw-medium">3.Innehållsproduktion</span> - Vi producerar innehållet som målgruppen vill se</li>
                                    <li><span className="fw-medium">4.Publicering</span> - Vi publicerar innehållet med passande bildtext, #hashtags och trendade ljud</li>
                                    <li><span className="fw-medium">5.Betald annonsering</span> - Vi betalar plattformarna för en plats i flödet hos målgruppen</li>
                                </ul>
                            </div>
                            <Button className="btn btn-primary mt-4" onClick={gotoContact}>Boka möte</Button>
                        </Col>
                        <Col md={6}>
                            <div className='h-100'>
                                <Image src="/assets/images/home-gif-new.gif" className="w-100 img-fit radius-14" />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section> */}

            <section className="pb-md-150 pb-100">
                <Container>
                    <div class="text-center pb-20">
                        <p class="font-22 fw-light mb-0">Vad våra kunder säger</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none"><path d="M0.5 1L9.08815 9L17.5 1" stroke="white"></path></svg>
                    </div>
                    <div className="testimonial-block p-20  mx-auto">
                        <Slider {...settings} ref={slider}>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Så oerhört skönt att inte längre behöva oroa sig över vad vi ska publicera varje vecka”</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/Booky__Logo.png" alt="Booky" />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Charlotte Dunhoff, VD</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image.png" className="img-fit rounded" />
                                </div>
                            </div>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Filmabbet har pricksäkert och prisvärt hjälpt oss att få en engagerad publik med flera tusentals följare över flera olika kanaler”</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/Booky__Logo white.png" alt="Booky" />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Thomas Andersson</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                </div>
                            </div>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Med Filmlabbet så får man det bästa för pengarna.”</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/image26.png" alt="Booky" className='mb-20 mb-md-0' />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Erik Ullsten</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image27.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image27.png" className="img-fit rounded" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </section>

            {/* <GridBlock title="Tidigare projekt" /> */}
            {/* <FAQ /> */}

            <section className="text-center pb-md-15 pb-100">
                <Container>
                    <h2 className="font-56 font-secondary mb-md-6 mb-3 maxw-616 mx-auto spacing-neg">Redo för en publik fylld av <span className='text-primary mr-1'> varma leads?</span> </h2>
                    <Link to="/kontakt" className="btn btn-primary ">Kontakt</Link>
                </Container>
            </section>

            <section className="text-center pb-md-15 pb-11">
                <Container>
                    <div className="d-flex justify-content-between flex-wrap gap-8">
                    <div className="logo-wrap">
                            <Image src="/assets/images/logos/factoringgruppen.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/Docens_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/ytterbygg_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky_logo.png" alt="Spotify" />
                        </div>
                    </div>
                </Container>
            </section>

            {/* <Footer /> */}
        </div>
    )
}

export default LandingPage;