import React, { useRef } from 'react';

import { Button, Col, Container, Image, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../component/Footer";


const PlayerPage = () => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: true,
        customPaging: function (i) {
            return (
                <div className='d-inline-flex align-items-center'>

                    <a current={i} goTo={i => slider.current.slickGoTo(i)}>
                        {i + 1}
                    </a>
                    <span className="total-slides">/ 3</span>
                </div>
            );
        },
        // customPaging: (i) => {
        //     return <CustomDots slides={slides} current={i} goTo={i => slider.slickGoTo(i)} />;
        // },
    };
    const slider = React.useRef(null);

    return (
        <div className="bg-gradientblock bottom-gradient pt-md-30 pt-6  landingpage homepage overflow-hidden">
            <nav className="bg-transparent text-white navbar navbar-expand-lg navbar-light mb-9">
                <Container className="justify-content-center">
                    <Link to="/" className="navbar-brand me-0">
                        <Image src="/assets/images/logo.svg" alt="Filmlabbet" />
                    </Link>
                </Container>
            </nav>

            <section className="pt-md-65 pt-0   pb-11 mt-neg40">
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                          <div className="font-18 fw-light d-flex flex-column gap-3 mb-md-0 mb-9 block max-476 text-md-left text-center mx-md-0 mx-auto">
                                <h4 className='fw-light mb-0'> Video - 17 minuter</h4>
                                <h1 className="font-46 fw-semibold font-secondary mb-0">
                                Hur du lyckas på  <br/> <span className='text-primary text-decoration-underline'>sociala medier</span> med  ditt <span className='text-primary text-decoration-underline'>byggbolag</span>
                                </h1>
                                <div className="opacity-90">
                                    <p className="letter-xs fw-light mb-20">Sammanfattat i 5 enkla steg som du kan göra själv redan idag!
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='form-block-wrapper position-relative'>
                                <div className='mobile-fix-height'>
                                    {/* <Image src="./assets/images/form-page-img.png" alt="Image1" className="rounded-4 img-fit" /> */}

                                    <iframe className=" only-mobile-width rounded-4 shadow-wrapper" width={682} height={450} src="https://player.vimeo.com/video/941942840?h=9764128097" title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pb-100 mt-neg40 text-center">
                <Container>
                    <h2 className="font-46 fw-semibold font-secondary mb-2 maxw-616 mx-auto pt-md-100">Vill du ha hjälp med era  <span className='text-primary'> sociala medier? </span></h2>
                    <div className="opacity-90 max-620">
                        <p className="letter-xs fw-light mb-0 font-20">Boka ett telefonsamtal med Marcus ifrån Filmlabbet.</p>
                    </div>
                    <Button variant="primary" type="button" className="mt-5">
                        Boka möte
                    </Button>
                </Container>
            </section>

            {/* <section className="pb-md-150 pb-100">
                <Container>
                    <div class="text-center pb-20">
                        <p class="font-22 fw-light mb-0">Såhär säger kunden</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none"><path d="M0.5 1L9.08815 9L17.5 1" stroke="white"></path></svg>
                    </div>
                    <div className="testimonial-block p-20  mx-auto">
                        <Slider {...settings} ref={slider}>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Så oerhört skönt att inte längre behöva oroa sig över vad vi ska publicera varje vecka”</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/Booky__Logo.png" alt="Booky" />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Charlotte Dunhoff, VD</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image.png" className="img-fit rounded" />
                                </div>
                            </div>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Filmabbet har pricksäkert och prisvärt hjälpt oss att få en engagerad publik med flera tusentals följare över flera olika kanaler”
                                        på bara några månader.</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/Booky__Logo white.png" alt="Booky" />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Thomas Andersson</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image25.png" className="img-fit rounded" />
                                </div>
                            </div>
                            <div className="gap-80 d-flex flex-wrap">
                                <div className="testimonial-leftblock">
                                    <h5 className="font-20 mb-20 fw-light mobile-font-14">“Med Filmlabbet så får man det bästa för pengarna.”</h5>
                                    <div className='d-md-block d-flex align-items-end justify-content-between'>
                                        <div className="align-items-center d-md-flex flex-wrap gap-40 minh-50 pb-md-0 pb-35">
                                            <Image src="/assets/images/image26.png" alt="Booky" className='mb-20 mb-md-0' />
                                            <span className="font-20 fw-light testimonial-text mobile-font-12">Erik Ullsten</span>
                                        </div>
                                        <div className="w-165 h-165 rounded d-md-none d-block overflow-hidden flex-shrink-0">
                                            <Image src="/assets/images/image27.png" className="img-fit rounded" />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-165 h-165 rounded d-md-block d-none">
                                    <Image src="/assets/images/image27.png" className="img-fit rounded" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </section> */}

            {/* <Footer noLine={true} /> */}
        </div>
    )
}

export default PlayerPage;