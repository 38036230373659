import React, { useState, useEffect } from 'react';
import { Container, Image, Table, Button } from "react-bootstrap";
import moment from 'moment'
import { contact_us_list } from '../store/api/course.api';
import { useDispatch, useSelector } from 'react-redux';
const ContactAnalitics = () => {
    const dispatch = useDispatch();
   
    const [allCources, setAllCources] = useState();
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
        dispatch(contact_us_list({})).then((course) => {
            setAllCources(course.payload?.data);
        });
    }
  
    return (
        <>
            <div>
               
                <Container>
                    {/* Starts Table */}
                    <div className='wow fadeInDown'>
                        <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
                            <span className="d-block  font-24 fw-light mb-2">Contact Analitics</span>
                        </div>
                        <Table bordered hover responsive className='text-center'>
                            <thead>
                                <tr>
                                  <td>Index</td>
                                    <th>Namn</th>
                                    <th>business_name</th>
                                    <th>E-post</th>
                                    <th>phone</th>
                                    <th>Message</th>
                                    <th>Datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allCources && allCources.length > 0 && allCources?.map((item, index) => (
                                        <tr key={item.id} >
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>{item?.name}</td>
                                            <td>{item?.business_name}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.phone}</td>
                                            <td>{item?.message}</td>
                                            <td>{moment(item?.contact_at).format("DD-MMM-YYYY HH:mm")}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                        {allCources && allCources.length === 0 &&
                            <p className='text-center'>Tomt</p>
                        }
                    </div>
                    {/* Ends Table */}
                </Container>
                
            </div>
        </>
    )
}

export default ContactAnalitics;