import { useState, useEffect } from "react";
import { Button, Form, Modal, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { addMedia,editActivity,singleActivity } from '../store/api/course.api';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const AddMedia= (prop)=> {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState({ title: "", sub_title: "",video_url:"",description:"" });
    const [selectedFile, setSelectedFile] = useState(null);
    const [activity_date, setActivity_date] = useState();
    const [activity_end_date, setActivity_end_date] = useState();
    const dispatch = useDispatch();
    const [textContent, setTextContent] = useState(null);
    
    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    useEffect(() => {

        if(prop.editId) {
            let data ={
               slug:prop.editId
            }
            dispatch(singleActivity(data)).then((act) => {
               
               setValue({
                ...value,
                ['title']: act.payload.data.title,
                ['sub_title']:act.payload.data.sub_title,
                ['video_url']:act.payload.data.video_url != "undefined" ?act.payload.data.video_url:'',
                ['live_url']:act.payload.data.live_url != "undefined" ?act.payload.data.live_url:'',
                ['book_me_url']:act.payload.data.book_me_url != "undefined" ?act.payload.data.book_me_url:'',
                ['see_course_url']:act.payload.data.see_course_url != "see_course_url" ?act.payload.data.see_course_url:'',
              
              
              });        
              setActivity_date(new Date(moment(act.payload.data.activity_date).format('YYYY-MM-DD HH:mm')));
              setActivity_end_date(new Date(moment(act.payload.data.activity_end_date).format('YYYY-MM-DD HH:mm')));
              setTextContent(act.payload.data.description);
             }); 
           }
        setShow(prop.show)
    }, []);
    const onChange = (e) => {
        
       
         setValue({
           ...value,
           [e.target.name]: e.target.value,
         });
       };

       const onAdd = () => {
       
        var data = new FormData();
        data.append('title', value.title);
        data.append('project_id', prop.project_id);
       
        if(selectedFile) {
        data.append('image', selectedFile, selectedFile.name);
        }
        
        
        
         dispatch(addMedia(data))
         .unwrap()
         .then(() => {
            handleClose();
         
         })
         .catch(() => {
            handleClose();
         });
       
      };
      const onEdit = () => {
      
        var data = new FormData();
        if(selectedFile) {
           data.append('image', selectedFile, selectedFile.name);
        }
        data.append('slug', prop.editId);
        data.append('title', value.title);
        data.append('sub_title', value.sub_title);
        data.append('video_url', value.video_url);
        data.append('description', textContent);
        data.append('category_id',prop.category_id)
        dispatch(editActivity(data))
        .unwrap()
        .then(() => {
           setShow(false);
           handleClose();
        })
        .catch(() => {
        });
     };   
    return (
        <div className="">
            <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton className="text-white d-block block text-center">
                    <Modal.Title className="d-block font-32"> {prop.editId? <span>{('Edit Medias')}</span>: <span>{('Add Medias')}</span>}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-white fw-light block font-22 fw-light">
                <Form className="form-block mx-auto max-387 custum-place ">
                        <Form.Group className="mb-4"  controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="Rubrik" className="no-border no_padding" name="title" autoComplete="off" aria-label="title" value={value.title}  onChange={onChange} />
                        </Form.Group>
                        <div className='mb-10 wow fadeInDown no-border no_padding mb4'>
                           <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                           <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                              <span className='file-icon'><Image src="/assets/images/Video_file_light.png" alt="Select Image" width="48" height="48" /></span>
                              <span>Välj bild</span>
                           </label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between text-center d-block">
                

                            {!prop.editId &&
                     <Button variant="primary" type="button" className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3" onClick={onAdd}>
                     Lägg till
                                 </Button>
                        }
                        {prop.editId &&
                    <Button variant="primary" type="button" className="btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3" onClick={onEdit}>
                    Update
                                </Button>
                        }
                  
                </Modal.Footer>
            </Modal>
        </div>
    )
} 

export default AddMedia